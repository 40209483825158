/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Nav2 from './Nav2';
import Banner5 from './Banner5';
import Banner4 from './Banner4';
import Banner6 from './Banner6';
import Banner7 from './Banner7';
import Banner8 from './Banner8';
import Feature2 from './Feature2';
import Feature1 from './Feature1';
import Footer0 from './Footer0';

import {
  Nav20DataSource,
  Banner50DataSource,
  Banner40DataSource,
  Banner60DataSource,
  Banner70DataSource,
  Feature20DataSource,
  Feature10DataSource,
  Feature21DataSource,
  Footer00DataSource,
  Banner80DataSource
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Nav2
        id="Nav2_0"
        key="Nav2_0"
        dataSource={Nav20DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner5
        id="Banner5_0"
        key="Banner5_0"
        dataSource={Banner50DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner4
        id="Banner4_0"
        key="Banner4_0"
        dataSource={Banner40DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner6
        id="Banner6_0"
        key="Banner6_0"
        dataSource={Banner60DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner7
        id="Banner7_0"
        key="Banner7_0"
        dataSource={Banner70DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature2
        id="Feature2_0"
        key="Feature2_0"
        dataSource={Feature20DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature1
        id="Feature1_0"
        key="Feature1_0"
        dataSource={Feature10DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature2
        id="Feature2_1"
        key="Feature2_1"
        dataSource={Feature21DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner8
        id="Banner8_0"
        key="Banner8_0"
        dataSource={Banner80DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer0
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <>
        <div
          className="templates-wrapper"
          ref={(d) => {
            this.dom = d;
          }}
        >
          {/* 如果不是 dva 2.0 替换成 {children} start */}
          {this.state.show && children}
          {/* 如果不是 dva 2.0 替换成 {children} end */}
          {/*  */}
        </div>
      </>
    );
  }
}
