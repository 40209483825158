import React from 'react';
export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper l78mv97hgvi-editor_css' },
  page: { className: 'home-page l78o02gcsp-editor_css' },
  logo: {
    className: 'header2-logo l7a1fpnokro-editor_css',
    children: 'https://img.shahaizi.cn/FnuNbtMmwOZDja8Rx6wzhb5L9HIZ',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: 'Banner5_0',
        children: '公司简介',
        className: 'menu-item l78n16vbzw-editor_css',
      },
      {
        name: 'linkNav~l78mikh9767',
        to: 'Banner4_0',
        children: '唯容农场APP',
        className: 'menu-item l78ncvk7ati-editor_css',
      },
      {
        name: 'linkNav~l78mim1bw9',
        to: 'Feature2_0',
        children: '业务优势',
        className: 'menu-item l78nyrdm9he-editor_css',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>南京朵朵菜农业科技有限公司</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'banner5-title l79shrama5s-editor_css',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <span>
              <p>公司简介</p>
            </span>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      加快发展现代农业，加快转变农业发展方式，提高农业综合生产能力、抗风险能力、市场竞争能力。这是“十四五”规划中关于大力推进农业现代化、有机化、可持续化发展的纲领性精神，在这个精神的指引下，现代农业发展面临着新的机遇，发展定制化农业将成为未来农业的基本要求。
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      公司旗下的“唯容农场”凭借前期发展所奠定的坚实基础，不断增强发展实力，发展精品农业、有机农业、休闲农业，打造成为具有一定影响的知名农业产业。为乡村振兴发展提供一个发展平台！
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#',
          className: 'banner5-button l78lre3s70t-editor_css',
          type: 'primary',
          children: '开始使用',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children: 'https://img.shahaizi.cn/FuB1M5OPiViFtWrQGBsi2SVdb4ht',
  },
};
export const Banner40DataSource = {
  wrapper: { className: 'home-page-wrapper banner4' },
  page: { className: 'home-page banner4-page' },
  childWrapper: {
    className: 'banner4-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>唯容农场</p>
          </span>
        ),
        className: 'banner4-title',
      },
      {
        name: 'content',
        className: 'banner4-content l79xhbzxdaq-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p style={{ lineHeight: 1.5 }}>
                      “唯容农场”一款可以在家租赁农场田地在线种菜、线下配送收菜的一体化服务全民种地APP。同时还可以通过官方商城购买绿色农产品，让用户吃的也放心！
                    </p>
                    <p>
                      <br />
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      功能描述：
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      1.APP主要面对农业、互联网、零售业，让用户体验云耕并吃到绿色农产品。
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      2.用户可以在线租赁田地并购买农作物的种子，等待农作物成熟后，去基地采摘或者邮递到家。
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      3.用户可以查看田地的监控画面，实时了解田地生长情况。
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      4.用户可以在商城购买农产品，健康绿色的农产品通过快递发给用户。
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      5.技术特点：功能强大，操作简便，并增强了用户体验。
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        children: {
          href: '#',
          type: 'primary',
          children: '开始使用',
          className: 'l79xcjnmrzr-editor_css',
        },
      },
    ],
  },
  image: {
    className: 'banner4-image l79xbjxvt4-editor_css',
    children: 'https://img.shahaizi.cn/Fjaw6bP5aMcAK6QeLwGO2j8W_ixr',
  },
};

export const Banner60DataSource = {
  wrapper: { className: 'home-page-wrapper banner6' },
  page: { className: 'home-page banner6-page' },
  childWrapper: {
    className: 'banner6-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>唯容店家</p>
          </span>
        ),
        className: 'banner6-title',
      },
      {
        name: 'content',
        className: 'banner6-content l79xhbzxdaq-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p style={{ lineHeight: 1.5 }}>
                      “唯容店家”app是一款专为在“唯容农场APP-商城模块”入驻的店铺提供的管理端APP。通过APP可以管理订单、店铺信息、店铺商品、与客户沟通等功能，让店家管理店铺更容易。
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      功能描述：
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      1.APP主要面对供应商、食品零售商、特产商家，让店铺管理更简单。
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      2.提供登陆注册功能，让线下商家注册成为商城的卖家，同时对他们身份进行审核
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      3.可以通过提供的各种功能对线上店铺进行管理：例如修改个人信息、发布商品、管理订单等
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      4.可以在线回复用户的各种问题，解决售前售后的疑问
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      5.可以通过订单功能进行发货，关注订单的物流情况
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      6.软件的所有功能操作，易学易用，简便方便，不需要教学既可以上手使用。
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        children: {
          href: '#',
          type: 'primary',
          children: '开始使用',
          className: 'l79xcjnmrzr-editor_css',
        },
      },
    ],
  },
  image: {
    className: 'banner6-image l79xbjxvt4-editor_css',
    children: 'v2_rjmndp.png',
  },
};

export const Banner70DataSource = {
  wrapper: { className: 'home-page-wrapper banner7' },
  page: { className: 'home-page banner7-page' },
  childWrapper: {
    className: 'banner7-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>唯容直配</p>
          </span>
        ),
        className: 'banner7-title',
      },
      {
        name: 'content',
        className: 'banner7-content l79xhbzxdaq-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p style={{ lineHeight: 1.5 }}>
                      “唯容直配”app是一款专为在“唯容农场APP”入驻的店铺提供的骑手端本地配送APP。通过APP，骑手可以看到应该配送的订单信息、用户信息。让骑手轻松完成商城订单的本地配送。
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      功能描述：
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      1.APP主要面对互联网、零售业、配送，让骑手配送更高效。
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      2.可以查看分配给骑手的订单信息。
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      3.骑手可以根据配送进度点击相关按钮和功能。
                    </p>
                    <p style={{ lineHeight: 1.5 }}>
                      4.如果配送中有问题，骑手可以通过订单直接跟客户沟通。
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        children: {
          href: '#',
          type: 'primary',
          children: '开始使用',
          className: 'l79xcjnmrzr-editor_css',
        },
      },
    ],
  },
  image: {
    className: 'banner7-image l79xbjxvt4-editor_css',
    children: 'v2_rjmnf3.png',
  },
};

export const Banner80DataSource = {
  wrapper: { className: 'home-page-wrapper banner8' },
  page: { className: 'home-page banner8-page' },
  childWrapper: {
    className: 'banner8-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>联系我们</p>
          </span>
        ),
        className: 'banner8-title',
      },
      {
        name: 'content',
        className: 'banner8-content l79xhbzxdaq-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      如果您有疑问或商务合作，请通过下面的联系方式与我们联系，以及获取相关技术支持。让我们合作共赢，携手成长！
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      公司名称：南京朵朵菜农业科技有限公司
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      公司地址：南京市栖霞区燕子矶街道和燕路408号晓庄国际广场1幢520室
                    </p>
                    <p>
                      <br />
                    </p>
                    <p>
                      技术支持：
                    </p>
                    <p>
                      <br />
                    </p>
                    <p >
                      联系人：林先生
                    </p>
                    <p >
                      联系电话：13851045909
                    </p>
                    <p >
                      邮箱地址：wrnc_202288@126.com
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      }
    ],
  }
};

export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: { children: 'https://img.shahaizi.cn/FjA4sNcLvVgXy5vbCvEYx_BRT4UR' },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <span>
          <p>1.公司地域优势巨大</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <span>
          <p>
            农业村落地处江苏东台，该地区环境指标、空气指标都是长江三角洲最好的，这里离国家级黄海森林公园和沿海滩涂直线距离10公里，优良的水、土、大气指标也为公司规划发展精品、有机农业奠定了得天独厚的自然基础。
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: { children: 'https://img.shahaizi.cn/FkEBtC4oQlrJOzlGDl7aEedMExGX' },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <p>2.公司外部市场空间巨大</p>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <p>
          随着人类经济社会的不断发展，以及环保观念的普及，人们越来越认识到，只有发展环境亲和度高的产业和有利人们健康的产品才能获得永续发展的机会，并获得长期的经济收益。在这样的背景之下，有机化农业、定制化农业、生态化养殖、乡村旅游、乡村美食渐成主流，成为现代城市居民的消费需求的热点之一。
        </p>
      </span>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: { children: 'https://img.shahaizi.cn/FjNPe5MPkE9iM6sLINeY_QCns99P' },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title',
    children: (
      <span>
        <span>
          <p>3.公司内部已形成鲜明的企业文化</p>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content',
    children: (
      <span>
        <p>
          股东目标一致，企业资金雄厚，管理科学有序，科技人才队伍、技术骨干队伍、市场营销团队不断壮大，对公司的远景发展形成强有力的支持。
        </p>
      </span>
    ),
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <span>
            <span>
              {/* <span>©2022&nbsp;&nbsp;</span> */}
              <span>©2022&nbsp;&nbsp;</span>
            </span>
          </span>南京朵朵菜农业科技有限公司 &nbsp;&nbsp;
          <span>
            <span><a href='https://beian.miit.gov.cn' target='_blank'>苏ICP备2022036661号-1</a></span>
          </span>
        </span>&nbsp;&nbsp;
        <span>
          <span>
            <span>
              <span><img src="备案图标.png" style={{ width: 14, height: 14 }} />&nbsp;<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011302321444" target="_blank" >苏公网安备 32011302321444号</a></span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
